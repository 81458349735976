.hotspot__figure {
  margin: 0;
  margin-bottom: 1rem;
  position: relative;
}

.hotspot__image {
  display: block;
  min-width: 100%;
  width: 100%;
}

.hotspot__spot {
  position: absolute;
  margin: -1rem;
  width: 2rem;
  height: 2rem;
  background-color: #0eb87f;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
  font-family: sans-serif;
  cursor: grab;
}

.hotspot__spot.react-draggable-dragging {
  cursor: grabbing;
}

.hotspot__spots {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
