html,
body,
div {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

.Card--With-DragHandle {
  position: relative;
  padding-left: 20px!important;
}

.Card__DragHandle {
  position: absolute;
  background-color: #0eb87f;
  width: 8px;
  top: 0;
  left: 0;
  bottom: 0;
}

.Card--With-DotHandle {
  position: relative;
  padding-left: 30px!important;
  margin-bottom: 8px;
}

.Card__DragHandle__Dots {
  position: absolute;
  background-color: #f7f9fa;
  top: 0;
  left: 0;
  bottom: 0;
  border: 0;
  width: calc(1rem * (20 / 16));
  border-right: 1px solid #d3dce0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  cursor: grab;
}